import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Viewer from './Viewer';
import classNames from 'classnames';

const UnstyledGallery = ({
  className,
  images,
  tag,
  visited,
  setVisited,
}) => {

  if (!(images && images.after && Object.keys(images.after).length)) return false;
  
  useEffect(() => {
    if (visited.includes(tag)) return;
    const categories = ['after', 'before'];
    categories.forEach(category => {
      Object.values(images[category]).forEach(({ url }) => {
        const elem = document.createElement('link');
        elem.rel = 'preload';
        elem.href = url;
        elem.as = 'image';
        document.body.append(elem);
      });
    });
    setVisited(tag);
  }, []);

  const [selected, setSelected] = useState(null);
  const [hovered, setHovered] = useState(null);

  const handleSelect = useCallback(
    image => setSelected(image),
    [setSelected]
  );

  const handleEnter = useCallback(
    name => setHovered(name),
    [setHovered]
  );

  const handleLeave = useCallback(
    () => setHovered(null),
    [setHovered]
  );

  const handleNextImage = useCallback(
    value => {
      if (!selected) return;
      const { index } = selected;
      const lastImagesIndex = Object.keys(images.after).length - 1;
      let newIndex = index + value;
      if (newIndex < 0) newIndex = lastImagesIndex;
      else if (newIndex > lastImagesIndex) newIndex = 0;
      const { name } = Object.values(images['after'])[newIndex];
      setSelected({
        before: images['before'][name],
        after: images['after'][name],
        index: newIndex,
      });
    },
    [selected, setSelected, images]
  );
  
  return (
    <div className={className}>
      {Object.values(images.after).map(({ url, name }, i) => {
        return (
          <div 
            key={url} 
            onMouseEnter={() => handleEnter(name)}
            onMouseLeave={handleLeave}
            className={classNames('image-container', hovered && hovered !== name && 'fade')}
            onClick={() => handleSelect({
              before: images['before'][name],
              after: images['after'][name],
              index: i,
            })}>
              <img src={url} alt={''} />
          </div>
        );
      })}
      {selected && (
        <Viewer 
          image={selected} 
          handleSelect={handleSelect} 
          handleNextImage={handleNextImage}
          tag={tag}
        />
      )}
    </div>
  );
};

const Gallery = styled(UnstyledGallery)`
  line-height: 0;
  -webkit-column-gap:   0px;
  -moz-column-gap:      0px;
  column-gap:           0px; 
  -moz-column-count:    3;
  -webkit-column-count: 3;
  column-count:         3;

  @media (max-width: 1000px) {
    & {
    -moz-column-count:    3;
    -webkit-column-count: 3;
    column-count:         3;
    }
  }
  @media (max-width: 800px) {
    & {
    -moz-column-count:    2;
    -webkit-column-count: 2;
    column-count:         2;
    }
  }
  @media (max-width: 400px) {
    & {
    -moz-column-count:    1;
    -webkit-column-count: 1;
    column-count:         1;
    }
  }

  & .image-container {
    cursor: pointer;
    width: 100% !important;
    height: auto !important;
    border: 1px solid white;
    box-sizing: border-box;

    & img {
      width: 100%;
    }
  }

  & .fade {
    opacity: 0.8;
  }
`;

export default Gallery;
