import React, { useState, useCallback, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import devices from './devices';

const UnstyledViewer = ({
  className,
  image,
  handleSelect,
  handleNextImage,
  tag,
}) => {

  useEffect(() => {
    setImageUrl(image.after.url)
  }, [image]);

  const [imageUrl, setImageUrl] = useState(image.after.url);

  const ref = useRef({});

  const handleImageClick = useCallback(() => {
    const newUrl = imageUrl === image.after.url ? image.before.url : image.after.url;
    setImageUrl(newUrl);
  },
    [imageUrl, image]
  );

  const handleClick = useCallback(
    e => ref.current && e.target === ref.current && handleSelect(null),
    []
  );

  return (
    createPortal(
      <div 
        onClick={handleClick}
        ref={ref}
        className={className}>
        <div className="logo" onClick={() => handleSelect(null)}>
          Sharron Diedrichs
        </div>
        <div className="close" onClick={() => handleSelect(null)}>
          <i className="fas fa-times fa-lg"></i>
        </div>
        <div 
          className={classNames('nextImage', 'previous')}
          onClick={() => handleNextImage(-1)}>
          <i className="fas fa-chevron-left fa-xs"></i>
        </div>
        <div className="image-container">
          <div className="tip">
            Click on image for before/after
          </div>
          <img
            onClick={handleImageClick}
            src={imageUrl}
            alt={''}
          />
        </div>
        <div
          className={classNames('nextImage', 'next')}
          onClick={() => handleNextImage(1)}>
          <i className="fas fa-chevron-right fa-xs"></i>
        </div>
      </div>,
      document.body
    )
  );
};

const Viewer = styled(UnstyledViewer)`
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  color: #BBB;

  .logo {
    position: absolute;
    left: 10px;
    top: 5px;
    font-size: 38px;
    color: #BBB;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 970px) {
      font-size: 24px;
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 40px;

    &:hover {
      cursor: pointer;
    }
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .tip {
      height: 3vh;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      color: #666;
      text-align: center;
    }

    img {
      max-height: 94vh;
      max-width: 94vw;
      border: 0;
      outline: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
  
      &:hover {
        cursor: pointer;
      }
    }
  }

  & .nextImage {
    position: absolute;
    top: 50vh;
    width: 100px;
    height: 100px;
    font-size: 60px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${devices.tablet} {
      width: 50px;
      height: 50px;
      font-size: 30px;
    }
  }

  & .previous {
    left: 0;
  }

  & .next {
    right: 0;
  }

  & .tag {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
    cursor: pointer;
  }

  // & img {
  //   max-width: 80vw;
  //   max-height: 80vh;
  // }
`;

export default Viewer;
