import React from 'react';
import styled from 'styled-components';

const UnstyledAbout = ({
  backgroundImage,
  className,
  image
}) => (
  <div className={className}>
    <p>Los Angeles/NYC based Advertising/Editorial Retoucher and Visual Artist.</p>
    <p>Contact: sharronlyn@gmail.com</p>
  </div>
);

const About = styled(UnstyledAbout)`
  width: 100vw;
  height: 100vh;
  background-image: ${({ image }) => image ? `url('${image.url}')` : 'none'};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 300;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
  
  p {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
  }
`;

export default About;
