import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import devices from './devices';

const UnstyledNavigation = ({
  className,
  location
}) => (
  <div className={className}>
    <div className={'header'}>
      <Link to={'/'}>
        Sharron Diedrichs
      </Link>
      {(location.pathname === '/' || location.pathname === '/about') && (
        <div className={'description'}>
          Retoucher Los Angeles/NYC
        </div>
      )}
    </div>

    <div className={'links'}>
      <LinkContainer>
        <Link to={'/beauty'}>
          Beauty
        </Link>
      </LinkContainer>
      <LinkContainer>
        <Link to={'/advertising'}>
          Still
        </Link>
      </LinkContainer>
      <LinkContainer>
        <Link to={'/about'}>
          About
        </Link>
      </LinkContainer>
    </div>
  </div>
);

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  a {
    font-size: 30px;
    color: white;
    text-decoration: none;
    font-weight: 300;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);

    &:focus, &:visited, &:link, &:active {
      color: white;
      text-decoration: none;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);

    @media ${devices.tablet} {
      background: none;
    }
  }
`;

const Navigation = styled(UnstyledNavigation)`
  position: fixed;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 100;
  margin-top: 10px;

  @media ${devices.tablet} {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      font-size: 48px;
      color: white;
      text-decoration: none;
      font-weight: 300;
      text-transform: uppercase;
      text-align: center;
      display: flex;
      justify-content: center;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
  
      &:focus, &:visited, &:link, &:active {
        color: white;
        text-decoration: none;
      }

      @media ${devices.mobile} {
        font-size: 32px;
      }
    }

    .description {
      color: white;
      font-weight: 300;
      font-size: 24px;
      text-align: center;
      display: flex;
      justify-content: center;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
    }
  }

  .links {
    display: flex;
  }
`;

export default withRouter(Navigation);
