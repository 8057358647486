import React from 'react';
import styled from 'styled-components';

const UnstyledFooter = ({
  className
}) => (
  <div className={className}>
    <a 
      className="ig-container"
      href="https://www.instagram.com/sharron.diedrichs/"
      rel="noreferrer noopener"
      target="_blank"
    >
      <i className="fab fa-instagram fa-2x"></i>
    </a>
    <a 
      className="mail-container"
      href="mailto:sharronlyn@gmail.com"
    >
      <i className="far fa-envelope fa-lg"></i>
    </a>
  </div>
);

const Footer = styled(UnstyledFooter)`
  position: fixed;
  display: flex;
  left: 5%;
  bottom: 5%;

  .ig-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 5px;
    border-radius: 20%;
    background-color: grey;
    color: white;
    margin-right: 10px;
    text-decoration: none;
  }

  .mail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    border-radius: 50%;
    background-color: black;
    color: white;
    text-decoration: none;
  }
`;

export default Footer;
