import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Navigation from "./Navigation";
import Gallery from "./Gallery";
import Home from "./Home";
import About from "./About";
import Footer from "./Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import classNames from "classnames";

const url = "https://www.sharron-diedrichs.com/.netlify/functions/resources";
// const url = 'http://localhost:5000/resources';

const UnstyledApp = ({ className }) => {
  const [resources, setResources] = useState(null);
  const [visited, setVisited] = useState([]);

  useEffect(() => {
    const fetchResoures = async () => {
      const response = await fetch(url);
      const data = await response.json();
      setResources(data);
    };
    fetchResoures();
  }, []);

  const restProps = {
    visited,
    setVisited,
  };

  return (
    <div className={classNames(className, "tk-district-pro")}>
      <Router>
        <Navigation />
        <div className={"container"}>
          <Switch>
            <Route
              exact
              path={"/"}
              render={(props) => (
                <Home image={resources && resources.frontPage} {...props} />
              )}
            />
            <Route
              exact
              path={"/beauty"}
              render={(props) => (
                <Gallery
                  images={resources && resources.beauty}
                  tag={"beauty"}
                  {...props}
                  {...restProps}
                />
              )}
            />
            <Route
              exact
              path={"/advertising"}
              render={(props) => (
                <Gallery
                  images={resources && resources.advertising}
                  tag={"advertising"}
                  {...props}
                  {...restProps}
                />
              )}
            />
            <Route
              exact
              path={"/fashion"}
              render={(props) => (
                <Gallery
                  images={resources && resources.fashion}
                  tag={"fashion"}
                  {...props}
                  {...restProps}
                />
              )}
            />
            <Route
              exact
              path={"/about"}
              render={(props) => (
                <About
                  image={resources && resources.aboutMe}
                  backgroundImage={resources && resources.frontPage}
                  {...props}
                />
              )}
            />
          </Switch>
          <Footer />
        </div>
      </Router>
    </div>
  );
};

const App = styled(UnstyledApp)`
  display: flex;
  justify-content: center;

  & .container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export default App;
