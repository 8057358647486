import React from 'react';
import styled from 'styled-components';

const UnstyledHome = ({
  className,
  image
}) => (
  <div className={className} />
);

const Home = styled(UnstyledHome)`
  width: 100vw;
  height: 100vh;
  background-image: ${({ image }) => image ? `url('${image.url}')` : 'none'};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center; 
`;

export default Home;
